// Arquivo criado: 22/11/2023 às 21:45

import { store } from '../redux/store'

export const eventLink = (): string => {

  const email = store.getState().user.perfil?.email
  const fullName = store.getState().user.perfil?.fullName

  if (!email || !fullName) return ''

  const jsonInfo = JSON.stringify({
    email,
    fullName
  })

  const base64Info = btoa(jsonInfo)

  return `https://www.liveevents.tv.br/einstein-2024/einstein-2024-chegada?info=${base64Info}`
}
